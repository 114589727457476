.drawer-head {
    background-color: #04070E;
    font-family: "Avenir Next Condensed" !important;
}

.drawer-middle {
    background: linear-gradient(#060913, #151c39);
    
}

.MuiTypography-h4{
    font-family: "Avenir Next Condensed" !important;
}

.MuiTypography-body1{
    font-family: "Avenir Next Condensed" !important;
}