.button {
  text-transform: none;
  background: black;
  color: white;
  text-transform: uppercase;
  font-weight: bold;
  border-radius: 0;
  letter-spacing: 1px;
  padding: 10px 20px;
  transition: all 0.2s;

  &:hover {
    background: black;
  }

  &.secondary {
    background: white;
    color: black;
    padding: 10px 30px;
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}